import { Observable, of } from 'rxjs'

const TOKEN_STORAGE_KEY = 'okta-token-storage'

export class TokenService {



      /**
       * This function retrieves the access token from local storage and returns it as an Observable.
       *
       * @return {Observable<string | null>} An Observable that emits the access token, or null if it doesn't exist.
       */
      public static getAccessToken = (): Observable<string | null> => {
            const octaTokenStr: string | null = localStorage.getItem(TOKEN_STORAGE_KEY);
            if (!octaTokenStr) { return of(null); };
            const octaToken = JSON.parse(octaTokenStr);
            const accessToken = octaToken?.idToken?.idToken;
            return of(accessToken);
      }
}