import { AssetsService, AssetType } from "./assetsService";
import { merge } from "lodash";

const LOCAL_TRANSLATIONS = false;
const USE_LOCAL_TRANSLATIONS_KEY = 'USE_LOCAL_STRANSLATIONS';
const TRANSLATION_PATTERN = /t\{\{(.+?)\}\}/;

export enum LanguageCode {
  EN = "en",
}

export enum LanguageName {
  ENGLISH = "English",
}

export interface Language {
  shortName: LanguageCode
  longName: LanguageName
}

export const getNestedProp = (obj: any, path: string) => path.split('.').reduce((p, c) => {
  if (p) {
    if (p[c] === 0) {
      return '0';
    } else {
      return p[c] || null;
    }
  } else {
    return null;
  }
}, obj);

export const replaceParam = (body: string, param: string, value: string) => {
  const iterLiteral = '\\{' + param + '\\}';
  const re = new RegExp(iterLiteral, 'g');
  return body.replace(re, value);
};

export const getAllParams = (body: string) => {
  return [...body.matchAll(/\{(.*?)}/g)].map(a => a[1]);
};

export class TranslationService {
  private static instance: TranslationService
  private readonly languages: { [key: string]: Language }
  private translations: any = null;
  private lang: string = LanguageCode.EN

  private constructor() {
    this.languages = {
      [LanguageCode.EN]: {
        shortName: LanguageCode.EN,
        longName: LanguageName.ENGLISH,
      },
    }
  }

  public get currentLang(): string {
    return this.lang;
  }

  public static getInstance(): TranslationService {
    if (!TranslationService.instance) {
      TranslationService.instance = new TranslationService()
    }

    return TranslationService.instance
  }

  public getTranslation(lang: string, mergeData = false, ignoreStandard = false) {
    const fetchTranslations = async (lang: string) => {
      this.lang = lang;
      const data = await AssetsService
        .getAsset(AssetType.Translation, lang, this.useLocalTranslations, false, true, ignoreStandard)
        .toPromise();
      const translations = this.transformTranslationFile(data);
      if (mergeData) {
        this.translations = merge(this.translations, translations)
      } else {
        this.translations = translations;
      }
      return this.translations;
    };

    return fetchTranslations(lang);
  }

  transformTranslationFile(payload: any): any {
    const flattenObject = (obj, prefix = '') =>
      Object.keys(obj).reduce((acc, k) => {
        const pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[k] === 'object' && k !== 'contentList') {
          Object.assign(<any>acc, flattenObject(obj[k], pre + k));
        } else {
          if (k !== 'contentList') {
            acc[pre + k] = obj[k];
          } else {
            acc[pre.slice(0, -1)] = obj[k];
          }
        }
        return acc;
      }, {});

    const flatResponse = flattenObject(payload.contentResponse);
    Object.keys(flatResponse).forEach(k => {
      Array.isArray(flatResponse[k]) && (flatResponse[k] = flatResponse[k].map(l => ({
        [l.name]: l.value ?
          l.value : ''
      })).reduce((obj, item) => ({ ...obj, ...item }), {}));
    });
    return flatResponse;
  }

  public interpolate(template: string, params: any = {}) {
    const _params: string[] = getAllParams(template) || [];
    let output = template;
    _params.forEach((param: string) => output = replaceParam(output, param, getNestedProp(params, param)))
    return output;
  }

  public translate(path: string, params: any = {}) {
    const parts = path.split('.');
    const key = parts.pop() || 'default';

    if (this.translations && this.translations[parts.join('.')] && this.translations[parts.join('.')][key]) {
      return this.interpolate(this.translations[parts.join('.')][key], params);
    } else {
      return key;
    }
  };

  public getAllLanguages(): Language[] {
    return Object.values(this.languages)
  }

  public getLanguageByShortName(shortName: LanguageCode): Language | undefined {
    return this.languages[shortName]
  }

  public getLanguagesExcept(key: LanguageCode): Language[] {
    return this.getAllLanguages().filter((language) => language.shortName !== key)
  }

  private get useLocalTranslations(): boolean {
    return !LOCAL_TRANSLATIONS ? sessionStorage.getItem(USE_LOCAL_TRANSLATIONS_KEY) === '1' || false : true
  }

  public translateJSON(jsonData: any): any {
    if (typeof jsonData === 'object') {
      const _jsonData = Array.isArray(jsonData) ? [...jsonData] : { ...jsonData };
      for (let key in _jsonData) {
        if (_jsonData.hasOwnProperty(key)) {
          if (typeof _jsonData[key] === 'object') {
            _jsonData[key] = this.translateJSON(_jsonData[key]);
          } else if (typeof _jsonData[key] === 'string') {
            if (TRANSLATION_PATTERN.test(_jsonData[key])) {
              const translationKey = TRANSLATION_PATTERN.exec(_jsonData[key])[1];
              _jsonData[key] = this.translate(translationKey);
            }
          }
        }
      }
      return _jsonData;
    } else {
      return null;
    }
  }


}