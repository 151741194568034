

declare var window: any;

export enum JsonModule {
      "federation" = "/config/federatedModules.json",
      "environment" = "/config/environment.config.json",
      "okta" = "/config/okta.config.json",
      "endPoints" = "/config/endpoints.json"
}

export class JsonResolver {
      private static jsonData: any = {};

      public static loadAllJson = () => {
            return new Promise(async (res: any) => {
                  if (!JsonResolver.jsonData || !JsonResolver.jsonData[JsonModule.federation]) {
                        
                        await Promise.all([
                              JsonResolver.resolveJson(JsonModule.federation),
                              JsonResolver.resolveJson(JsonModule.okta),
                              JsonResolver.resolveJson(JsonModule.environment),
                              JsonResolver.resolveJson(JsonModule.endPoints)
                        ])
                        res();
                  } else {
                        res();
                  }
            })
      }

      public static getJsonData = (jsonModule: JsonModule) => {
            return JsonResolver.jsonData[jsonModule];
      }

      private static resolveJson = async (jsonModule: JsonModule) => {

            let md: any = jsonModule;
            if (JsonResolver.jsonData[jsonModule]) {
                  return new Promise((resolve) => resolve(JsonResolver.jsonData[jsonModule]));
            } else {
                  return fetch(md)
                        .then(async (req: any) => {
                              JsonResolver.jsonData[jsonModule] = await req.json();
                              return JsonResolver.jsonData[jsonModule];
                        })
                        .catch((error: any) => {
                              console.error('Error loading json module:', error);
                              throw error;
                        })
            }
      }
}

