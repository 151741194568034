import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RegistrationUtils } from "@snp/libraries/utils";
import { HttpService } from "./httpService";
import { AssetsService, AssetType } from "./assetsService";

const SELECTED_STANDARD_STORAGE_KEY = 'selected-standard';
const SELECTED_STANDARD_ID_STORAGE_KEY = 'selected-standard-id';


export interface StandardModel {
    id: string;
    name: string
    metaData: string;
}

export class StandardService {

    private static _selectedStandard: StandardModel | null;

    /**
     * This function retrieves the selected standard.
     *
     * @return {string | null} An string representing selected standard, or null if it doesn't exist.
     */
    public static getSelectedStandard = (): StandardModel | null => {
        if (StandardService._selectedStandard) {
            return StandardService._selectedStandard;
        }

        const selectedStandard: string | null = localStorage.getItem(SELECTED_STANDARD_STORAGE_KEY);
        if (!selectedStandard) {
            return null;
        };

        try {
            StandardService._selectedStandard = JSON.parse(selectedStandard);
            return StandardService._selectedStandard;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }

    /**
    * This function sets the selected standard.
    */
    public static setSelectedStandard = (standard: StandardModel): void => {
        localStorage.setItem(SELECTED_STANDARD_STORAGE_KEY, JSON.stringify(standard));
        StandardService._selectedStandard = standard;
    }

    public static clearSelectedStandard = (): void => {
        localStorage.removeItem(SELECTED_STANDARD_STORAGE_KEY);
        StandardService._selectedStandard = null;
    }

    /**
    * This function retrives all the avaliavle standards from the API
    */
    public static getAllStandards = (isPublic = false): Observable<StandardModel[]> => {
        const url = `${RegistrationUtils.getUrl("cmsResources")}/lookup/getTenantStandards/SPG`;
        return HttpService.get(url, isPublic)
            .pipe(
                map((result: StandardModel[]) =>
                    result.map((item: StandardModel) => ({
                        id: item.id,
                        metaData: item.metaData,
                        name: item.name
                    }))
                )
            )
    };

    /**
    * This function retrives standard logo image blob.
    */
    public static getStandardLogo = (standard: StandardModel): Observable<any> => {
        return AssetsService.getAsset(AssetType.StandardLogo, standard.metaData);
    };
}