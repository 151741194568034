import { BehaviorSubject, Observable, from } from "rxjs";
import { tap, shareReplay, switchMap, take, filter, map } from 'rxjs/operators';
import { RegistrationUtils } from "@snp/libraries/utils";
import { HttpService } from "./httpService";

// Enum to represent HTTP methods
enum HttpMethod {
    GET = 'get',
    POST = 'post'
}
export class LookupService {

    private static cache: { [key: string]: BehaviorSubject<any> } = {};


    private static genChecksum = async (uri: string, payload: any): Promise<string> => {
        const key = { uri, payload };
        const jsonString = JSON.stringify(key);
        const buffer = new TextEncoder().encode(jsonString);
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }

    public static get = (url: string, isPublic = false, forceReload = false): Observable<any> => {
        return LookupService.getData(url, null, isPublic, HttpMethod.GET, forceReload);
    }

    public static post = (url: string, payload: any, isPublic = false, forceReload = false): Observable<any> => {
        return LookupService.getData(url, payload, isPublic, HttpMethod.POST, forceReload);
    }

    public static getLookup = (url: string, payload: any, isPublic = false, forceReload = false): Observable<any> => {
        const lookupUrl = `${RegistrationUtils.getUrl('raasUri')}${url}`; 
        return LookupService.getData(lookupUrl, payload, isPublic, HttpMethod.POST, forceReload)
            .pipe(
                map(data => data.map((item: any) => ({ ...item, label: item.name, value: Number(item.id) }))),
            )
    }

    private static getData = (url: string, payload: any | null, isPublic = false, method = HttpMethod.POST, forceReload = false): Observable<any> => {   
        return from(LookupService.genChecksum(url, payload))
            .pipe(switchMap(key => {
                if (!this.cache[key] || forceReload) {
                    this.cache[key] = new BehaviorSubject(null);
            
                    let $req = HttpService.post(url, payload, isPublic)
                    if (method === HttpMethod.GET) {
                        $req = HttpService.get(url, isPublic)
                    }

                    const data$ = $req.pipe(
                            //map(data => data.map((item: any) => ({ ...item, label: item.name, value: Number(item.id) }))),
                            tap(data => this.cache[key].next(data)),
                            shareReplay(1)
                    );
                    return data$;
                }

                return this.cache[key].asObservable()
                    .pipe(
                        filter(d => d !== null),
                        take(1),
                    );
                }));
    }

    public static clear(): void {
        this.cache = {};
    }

}
