import { Observable, throwError } from "rxjs";
import { HttpService } from "./httpService";
import { RegistrationUtils } from "@snp/libraries/utils";
import { StandardService } from "./standardService";
import { ContextService } from "./contextService";
import { catchError } from "rxjs/operators";

// Will only use local files.
const USE_LOCAL_FILES_FLAG = false;
// Will try to fall back to local files when remote API fails.
const FALL_BACK_TO_LOCAL = true;

export const enum AssetType {
    Schema = 'Schema',
    SchemaOverride = 'SchemaOverride',
    SchemaPartial = 'SchemaPartial',
    TableConfig = 'TableConfig',
    TableConfigOverride = 'TableConfigOverride',
    Translation = 'Translation',
    StandardLogo = 'StandardLogo',
    Image = 'Image',
}

export class AssetsService {

    /**
     * Fetches assets data either from local assets directory or remote API.
     *
     * @param {AssetType} assetType - Asset type.
     * @param {string} configKey - ConfigKey of the asset.
     * @param {any} forceLocal - (Optional ) When set to true, will not try to fetch from API but use local file.
     * @param {any} forceFallBackToLocal - (Optional ) When set to true, will force fallback to local file.
     * @param {boolean} isPublic - (Optional) When set to true, will use unauthenticated call to the backend.
     * @param {boolean} ignoreStandard - (Optional) When set to true, will ignore the standard and fetch the asset without standard.
     * @param {boolean} defaultConfigKey - (Optional) TODO.
     * @return {Observable<any>} An Observable that emits the fetched data.
     */
    public static getAsset = (assetType: AssetType, configKey: string, forceLocal = false, forceFallBackToLocal = false, isPublic = false, ignoreStandard = false,
        defaultConfigKey?: string): Observable<any> => {
        return new Observable<any>((subscriber: any) => {
            if (USE_LOCAL_FILES_FLAG || forceLocal) {
                AssetsService.subsctibeToLocalFile(assetType, configKey, subscriber, ignoreStandard, defaultConfigKey, isPublic);
            }
            else {
                const assetUrl = AssetsService.getAssetUrl(assetType, configKey);
                const url = `${RegistrationUtils.getUrl("cmsResources")}${assetUrl}`;
                const headers = AssetsService.getAssetHeaders(assetType, configKey);
                const ignoredHeadersList = ignoreStandard ? ['standardAcronym'] : [];
                HttpService.get(url, isPublic, headers, (assetType === AssetType.StandardLogo || assetType === AssetType.Image), false, ignoredHeadersList)
                    .pipe(
                        catchError(error => {
                            if (defaultConfigKey) {
                                return AssetsService.getAsset(assetType, defaultConfigKey, forceLocal, forceFallBackToLocal, isPublic, ignoreStandard, defaultConfigKey);
                            } else {
                                return throwError(error);
                            }
                        }
                        )).subscribe(
                            (response: any) => {
                                subscriber.next(response);
                                subscriber.complete();
                            },
                            (error: any) => {
                                if (FALL_BACK_TO_LOCAL || forceFallBackToLocal) {
                                    AssetsService.subsctibeToLocalFile(assetType, configKey, subscriber,false,null, isPublic);
                                } else {
                                    subscriber.error(error);
                                    subscriber.complete();
                                }
                            });
            }
        });
    }

    private static subsctibeToLocalFile(assetType: AssetType, configKey: string, subscriber: any, ignoreStandard: boolean = false, defaultConfigFile: string = null, isPublic: boolean = false): any {
        const assetLocalUrl = AssetsService.getAssetLocalUrl(assetType, configKey);

        const isBlob = assetType === AssetType.StandardLogo || assetType === AssetType.Image;
        const selectedStandard = StandardService.getSelectedStandard();

        let _currentContext;
        if(isPublic) {
            _currentContext = ContextService.getCurrentPublicContext();
        }
        else {
            _currentContext = ContextService.getCurrentContext();
        }
        const currentContext = _currentContext ? (_currentContext.defaultStandard || _currentContext.registry) : null;

        if ((selectedStandard || currentContext) && !ignoreStandard) {
            const assetLocalUrlWithStandard = AssetsService.getAssetLocalUrl(assetType, configKey, selectedStandard ? selectedStandard.metaData : currentContext);
            fetch(assetLocalUrlWithStandard)
                .then((response: any) => {
                    if (!response.ok || (!isBlob && !AssetsService.isJsonResponse(response))) {
                        if (assetType !== AssetType.SchemaPartial) {
                            const fetchFallbackNoSTandard = () => {
                                fetch(assetLocalUrl)
                                    .then((response: any) => {
                                        if (!response.ok || (!isBlob && !AssetsService.isJsonResponse(response))) {
                                            subscriber.error('Local file not found.');
                                            subscriber.complete();
                                        }
                                        const contentPromise = isBlob ? response.blob() : response.json();
                                        contentPromise.then((json: any) => {
                                            subscriber.next(json);
                                            subscriber.complete();
                                        })
                                    })
                                    .catch((innerError: any) => {
                                        console.log('catch', innerError);
                                        subscriber.error(innerError);
                                        subscriber.complete();
                                    });
                            }

                            if (defaultConfigFile) {
                                console.log('Trying fallback to default config file');
                                const defaultAssetLocalUrlWithStandard = AssetsService.getAssetLocalUrl(assetType, defaultConfigFile, selectedStandard ? selectedStandard.metaData : currentContext);
                                fetch(defaultAssetLocalUrlWithStandard)
                                    .then((response: any) => {
                                        if (!response.ok || (!isBlob && !AssetsService.isJsonResponse(response))) {
                                            fetchFallbackNoSTandard();
                                        }
                                        const contentPromise = isBlob ? response.blob() : response.json();
                                        contentPromise.then((json: any) => {
                                            subscriber.next(json);
                                            subscriber.complete();
                                        })
                                    })
                                    .catch((innerError: any) => {
                                        console.log('catch', innerError);
                                        subscriber.error(innerError);
                                        subscriber.complete();
                                    });
                            } else {
                                fetchFallbackNoSTandard();
                            }
                        } else {
                            subscriber.next({});
                            subscriber.complete();
                        }
                    }
                    else {
                        const contentPromise = isBlob ? response.blob() : response.json();
                        contentPromise.then((json: any) => {
                            subscriber.next(json);
                            subscriber.complete();
                        }).catch((error: any) => {
                            subscriber.error(error);
                            subscriber.complete();
                        })
                    }
                })
                .catch((error: any) => {
                    console.log('catch', error);
                    subscriber.error(error);
                    subscriber.complete();
                });
        }
        else {
            fetch(assetLocalUrl)
                .then((response: any) => {
                    if (!response.ok) {
                        subscriber.error('Error response from the API.');
                        subscriber.complete();
                    }

                    const contentPromise = isBlob ? response.blob() : response.json();
                    contentPromise.then((json: any) => {
                        subscriber.next(json);
                        subscriber.complete();
                    }).catch((error: any) => {
                        subscriber.error(error);
                        subscriber.complete();
                    })
                })
                .catch((error: any) => {
                    subscriber.error(error);
                    subscriber.complete();
                });
        }
    }

    private static getAssetUrl = (assetType: AssetType, configKey: string): string => {
        switch (assetType) {
            case AssetType.Schema: {
                return `/resource/getContent/schema/${configKey}`;
            }
            case AssetType.SchemaOverride: {
                return `/resource/getContent/schemaOverrides/${configKey}`;
            }
            case AssetType.TableConfigOverride: {
                return `/resource/getContent/tableConfigOverrides/${configKey}`;
            }
            case AssetType.TableConfig: {
                return `/resource/getContent/table/${configKey}`;
            }
            case AssetType.Translation: {
                return `/resource/getContent/translation/${configKey}`;
            }
            case AssetType.StandardLogo: {
                return `/resource/download/logo/${configKey}.gif`;
            }
            case AssetType.Image: {
                return `/resource/download/image/${configKey}`;
            }
            case AssetType.SchemaPartial: {
                return `/resource/getContent/partials/${configKey}`;
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static getAssetHeaders = (assetType: AssetType, configKey: string): { [key: string]: string } => {
        switch (assetType) {
            case AssetType.Schema:
            case AssetType.SchemaOverride:
            case AssetType.TableConfigOverride:
            case AssetType.TableConfig:
            case AssetType.Translation:
            case AssetType.Image: {
                return {
                    'tenant': 'SPG'
                }
            }
            case AssetType.StandardLogo: {
                return {
                    'tenant': 'SPG',
                    'acronym': configKey
                }
            }
            case AssetType.SchemaPartial: {
                const _currentContext = ContextService.getCurrentContext();
                const currentContext = _currentContext ? (_currentContext.defaultStandard || _currentContext.registry) : null;
                return currentContext ? {
                    'standardAcronym': currentContext
                } : {}
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static getAssetLocalUrl = (assetType: AssetType, configKey: string, standard?: string): string => {
        switch (assetType) {
            case AssetType.Schema: {
                return standard ? `/standards/${standard}/schemas/${configKey}.json`
                    : `/schemas/${configKey}.json`
            }
            case AssetType.SchemaOverride: {
                return `/schemas/schemaOverrides/${configKey}.json`;
            }
            case AssetType.TableConfigOverride: {
                return `./json/table-config/tableConfigOverrides/${configKey}.json`;
            }
            case AssetType.TableConfig: {
                return standard ? `/standards/${standard}/table-config/${configKey}.json`
                    : `/json/table-config/${configKey}.json`
            }
            case AssetType.Translation: {
                return standard ? `/standards/${standard}/i18n/${configKey}.json`
                    : `/i18n/${configKey}.json`
            }
            case AssetType.StandardLogo: {
                return `./images/standard/${configKey}.gif`;
            }
            case AssetType.Image: {
                return `./images/${configKey}`;
            }
            case AssetType.SchemaPartial: {
                return `/schemas/partials/${standard ? `${standard}/` : ''}${configKey}.json`;
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static isJsonResponse(response: any): boolean {
        const contentType: string = response?.headers?.get("Content-Type");
        return contentType?.includes('application/json') || contentType?.includes('application/octet-stream') ? true : false;
    }
}
