import { HttpService } from "@snp/libraries/core";
import { MessageProps } from "packages/components/src/federated";
import { TIME_OUT } from "./constants";
import { RegistrationUtils } from "./registration-utils";

export class Utils {
  public static getAgGridLicense(): string {
    return process.env.agGridLicenseKey as string;
  }

  public static getDocOwnerEntityName = (
    entityType: string,
    docEntityType: string,
    docOwnerEntityName: string
  ) => {
    if (docEntityType === "Account") {
      return docEntityType;
    } else if (docEntityType === "Project") {
      return "MarkitProject";
    } else if (docEntityType === "Unit") {
      if (docOwnerEntityName === "MixedUnit") {
        return "MixedUnit";
      } else {
        return "Unit";
      }
    } else {
      return null;
    }
  };

  public static getDefaultTopRow = (columns: Array<any>[], extraParams: any = null) => {
    const topRow: any = {};
    if (columns?.length > 0) {
      columns.forEach((col: any) => {
        topRow[col.field] = "";
      });
    }
    return {
      ...topRow,
      id: `${new Date().getTime()}-new`,
      ...extraParams
    };
  };

  public static genUniqueId = () => {
    return Math.random().toString(16).slice(2);
  }

  public static resolveComponent = async (component: any) => {
    const _component = await component._payload._result();
    return _component.default;
  }

  public static getFilenameFromHeader = (contentDisposition: string | null, defaultFilename = 'filename.pdf') => {
    let filename = defaultFilename;
    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      let matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  public static numberFormatter = (value: number, defaultValue: string = '-') => {
    if (value && !isNaN(value)) {
      return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(value);
    } else {
      return defaultValue;
    }
  }

  public static downloadFile = (url: string, isPublic = false) => {
    HttpService.get(url, isPublic, null, true, true).subscribe(
        (res: any) => {
            const _blob = new Blob([res.blob], {
                type: "application/octet-stream",
            });
            if (_blob?.size) {
                const url = window.URL.createObjectURL(_blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = res.filename;
                a.click();
                URL.revokeObjectURL(url);
            }
        }
      );
  }

  public static getPropertyValue = (propertyName: string) => {
    const url = `${RegistrationUtils.getUrl("propertyManager")}/public/getValueByName/${propertyName}`;
    return HttpService.get(url, true);
  }

  public static replaceParam = (body: string, param: string, value: string) => {
    const iterLiteral = '\\{' + param + '\\}';
    const re = new RegExp(iterLiteral, 'g');
    return body.replace(re, value);
  };
  
  public static getAllParams = (body: string) => {
    return [...body.matchAll(/\{(.*?)}/g)].map(a => a[1]);
  };

  public static blobToDataURL = (blob:any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Event handler for when the read operation is complete
        reader.onloadend = () => {
            resolve(reader.result); // The result is the Data URL
        };
        // Event handler for when an error occurs
        reader.onerror = (error) => {
            reject(new Error('Failed to convert Blob to Data URL: ' + error?.message));
        };
        // Read the Blob as a Data URL
        reader.readAsDataURL(blob);
    });
  }

  public static formatErrorMessage = (errorMessage: string): string => {
    if (errorMessage.includes(":")) {
      const [prefix, documents] = errorMessage.split(": ");
      const documentList = documents.split(", ");
      return `${prefix}:<ul>${documentList.map(doc => `<li>${doc}</li>`).join("")}</ul>`;
    }
    return errorMessage;
  };

  public static formatDocumentDetails = (entity: { [key: string]: string[] }) => {
    return Object.entries(entity).map(([_, items]) =>
      `<ul>${items.map(item => `<li>${item}</li>`).join('')}</ul>`
    ).join('');
  };

  public static alertDurationToClose = (duration:number | undefined) => {
    return duration || duration === 0 ? duration * 1000 : TIME_OUT;
  }

  public static showMessage = (
    message: string,
    setMessageOptions: any,
    errorFlag: boolean,
    skipTranslate: boolean = true,
    options?: {
      navigateToTarget?: string,
      duration?: number
    }

  ) => {
    const setMessage: MessageProps = { skipTranslate };
    Object.assign(
      setMessage,
      errorFlag ? { errorName: message } : { successMessage: message }
    );
    const time_duration = this.alertDurationToClose(options?.duration);
    setMessageOptions({...setMessage, ...options});
    setTimeout(() => {
      setMessageOptions({});
    }, time_duration);
  };

}
