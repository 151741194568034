import { Observable, throwError, from, of } from "rxjs";
import { catchError, mergeMap, switchMap, retry, map } from "rxjs/operators";
import { fromFetch } from "rxjs/fetch";
import { RegistrationUtils, Utils } from "@snp/libraries/utils";
import { TokenService } from "./tokenService";
import { v4 as uuidv4 } from 'uuid';
import { TranslationService } from "./translationService";
import { ContextService } from "./contextService";

const REQUEST_ID_HEADER = 'x-request-id';
const RETRY_COUNT = 2;

export class HttpService {
  /**
   * Gets the access token from the token service.
   * @returns {Observable<string | null>} An observable that emits the access token.
   * @throws {Error} If the access token is null.
   **/
  private static getToken = (): Observable<string | null> => {
    return TokenService.getAccessToken();
  };

  /**
   * Fetches data from the specified URL and returns an Observable.
   *
   * @param {string} url - The URL to fetch data from.
   * @param {boolean} isPublic - Indicates public endpoint which does not require authorization
   * @param {any} customHeaders - The custom headers that should be passed along with the request
   * @param {boolean} isBlob - Indicates whether the response is a blob, default false.
   * @param {boolean} blobWithFilename - Indicates whether the response is a blob with filename, default false.
   * @param {string[]} omitHeadersList - List of headers to omit from the request.
   * @return {Observable<any>} An Observable that emits the fetched data.
   */
  public static get(
    url: string,
    isPublic: boolean = false,
    customHeaders: any = {},
    isBlob = false,
    blobWithFilename = false,
    omitHeadersList: string[] = [],
  ): Observable<any> {
    const preRequest = isPublic ? of(null) : this.getToken();
    return from(preRequest).pipe(
      mergeMap((token: string | null) => {
        if (!isPublic && !token) {
          return throwError(new Error("Token is null"));
        }
        const headers = Utils.omit({
          ...HttpService.commonHeaders(isPublic),
          ...RegistrationUtils.getDefaultHeaders(),
          ...customHeaders
        }, omitHeadersList);

        !isPublic && (headers["Authorization"] = `Bearer ${token}`);
        return fromFetch(url, {
          method: "GET",
          headers,
        }).pipe(
          // Retry only if response is in bellow criteria.
          // Only when bellow map throws.
          map((response) => {
            if (response?.ok) {
              return response;
            }

            switch (response?.status) {
              case 400: // Bad request
              case 401: // Auth
              case 408: // Timeout
                {
                  console.error(`An error occurred. Url: [${url}]. Retrying.`);
                  throw new Error("Request failed");
                }
            }

            if (response?.status >= 500) {
              console.error(`An error occurred. Url: [${url}]. Retrying.`);
              throw new Error("Request failed");
            }

            return response;
          }),
          retry(RETRY_COUNT),
          switchMap((response) => {
            if (response.ok) {
              if (isBlob && blobWithFilename) {
                const contentDisposition = response.headers.get('content-disposition');
                const filename = Utils.getFilenameFromHeader(contentDisposition);
                return response.blob().then(blob => ({ blob, filename }))
              }
              return isBlob ? response.blob() : response.json();
            } else {
              if (!isBlob) {
                response.json()
                  .then((data) => {
                    console.log("Error Occured Sending request: " + url + "\nError Code: " + data.errorCode + "\nError Trace: " + data.errorMsg);
                  })
                  .catch((err) => {
                    console.log("Error Occured Sending request: " + url);
                  });
              }
              throw new Error("Request failed");
            }
          }),
          catchError((error) => {
            // handle the error here
            console.error("An error occurred:", error);
            return throwError(error);
          })
        );
      })
    );
  }

  /**
   * Sends a POST request to the specified URL with the provided data.
   *
   * @param {string} url - The URL to send the request to.
   * @param {any} data - The data to send in the request body.
   * @param {any} customHeaders - The custom headers that should be passed along with the request
   * @param {boolean} isPublic - Indicates public endpoint which does not require authorization
   * @param {boolean} isFile -  if the respons etype is file
   * @return {Observable<any>} An observable that emits the response data.
   */
  public static post(
    url: string,
    data: any,
    isPublic: boolean = false,
    isFile: boolean = false,
    customHeaders: any = {},
  ): Observable<any> {
    const preRequest = isPublic ? of(null) : this.getToken();
    return from(preRequest).pipe(
      mergeMap((token: string | null) => {
        if (!isPublic && !token) return throwError(new Error("Token is null"));

        const headers = {
          "Content-Type": "application/json",
          ...HttpService.commonHeaders(isPublic),
          ...RegistrationUtils.getDefaultHeaders(),
          ...customHeaders
        };
        !isPublic && (headers["Authorization"] = `Bearer ${token}`);

        return fromFetch(url, {
          method: "POST",
          headers,
          body: data instanceof FormData ? data : JSON.stringify(data),
        }).pipe(
          switchMap(async (response) => {
            if (response.ok) {
              if (isFile) {
                return response.blob();
              } else {
                return response.json();
              }

            } else {

              await response.json().then((data) => {
                throw new Error(data[0].errorMessage);
                // console.log("Error Occured Sending request: " + url + "\nError Code: " + data.errorCode + "\nError Trace: " + data.errorMsg);
              });
            }
          }),
          catchError((error) => {
            console.error(error);
            return throwError(error.message);
          })
        );
      })
    );
  }

  /**
   * Sends a DELETE request to the specified URL with the provided data.
   *
   * @param {string} url - The URL to send the request to.
   * @param {any} data - The data to send in the request body.
   * @param {any} customHeaders - The custom headers that should be passed along with the request
   * @param {boolean} isPublic - Indicates public endpoint which does not require authorization
   * @return {Observable<any>} An observable that emits the response data.
   */
  public static delete(
    url: string,
    isPublic: boolean = false,
    customHeaders: any = {},
  ): Observable<any> {
    const preRequest = isPublic ? of(null) : this.getToken();
    return from(preRequest).pipe(
      mergeMap((token: string | null) => {
        if (!isPublic && !token) return throwError(new Error("Token is null"));

        const headers = {
          "Content-Type": "application/json",
          ...HttpService.commonHeaders(isPublic),
          ...RegistrationUtils.getDefaultHeaders(),
          ...customHeaders
        };
        !isPublic && (headers["Authorization"] = `Bearer ${token}`);

        return fromFetch(url, {
          method: "DELETE",
          headers,
        }).pipe(
          switchMap((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("Request failed");
            }
          }),
          catchError((error) => {
            console.error("An error occurred:", error.message);
            return throwError(error);
          })
        );
      })
    );
  }

  public static fileUpload(
    url: string,
    data: any,
    isPublic: boolean = false,
    isFile: boolean = false,
    customHeaders: any = {},
  ): Observable<any> {
    const preRequest = isPublic ? of(null) : this.getToken();
    return from(preRequest).pipe(
      mergeMap((token: string | null) => {
        if (!isPublic && !token) return throwError(new Error("Token is null"));

        const headers = {
          // "Content-Type": "application/json",
          ...HttpService.commonHeaders(isPublic),
          ...RegistrationUtils.getDefaultHeaders(),
          ...customHeaders
        };
        !isPublic && (headers["Authorization"] = `Bearer ${token}`);

        return fetch(url, {
          method: "POST",
          headers,
          body: data,
        }).then((response) => {
          if (response.ok) {
            if (isFile) {
              return response.blob();
            } else {
              return response.json();
            }

          } else {
            response.json().then((data) => {
              console.log("Error Occured Sending request: " + url + "\nError Code: " + data.errorCode + "\nError Trace: " + data.errorMsg);
            });
            throw new Error("Request failed");
          }
        }).catch((error) => {
          console.error("An error occurred:", error.message);
          return throwError(error);
        });
      })
    );
  }

  private static commonHeaders(isPublic: boolean = false): any {
    const translationService = TranslationService.getInstance();
    const selectedStandard = JSON.parse(localStorage.getItem('selected-standard')) ?? null;

    const currentContextName = ContextService.getCurrentContextName();
    const context = ContextService.getContext(currentContextName as string);

    const headers: any = {
      [REQUEST_ID_HEADER]: uuidv4(),
      'Accept': 'application/json',
      language: translationService.currentLang,
    }

    if (selectedStandard) {
      headers['standardId'] = selectedStandard.id;
      headers['standardAcronym'] = selectedStandard.metaData;
    }

    if (!selectedStandard && context && isPublic) {
      headers['standardAcronym'] = context.registry;
    }

    if (isPublic) {
      const publicContextName = ContextService.getPublicContextName();
      const publicContext = ContextService.getContext(publicContextName as string);

      if (!selectedStandard && publicContext) {
        headers['standardAcronym'] = publicContext.defaultStandard || publicContext.registry;
      }

      if (publicContext) {
        headers['registry'] = publicContext.registry;
      }
    }
    else {
      if (context) {
        headers['registry'] = context.registry;
      }
    }

    return headers;
  }

}
