import { JsonModule, JsonResolver } from "@snp/libraries/config";
import dayjs from "dayjs";

export class RegistrationUtils {
  private static Env = () => {
    return JsonResolver.getJsonData(JsonModule.environment)
  }
  public static getNestedProp = (obj: any, path: string) =>
    path.split(".").reduce((p, c) => {
      if (p) {
        if (p[c] === 0) {
          return "0";
        } else {
          return p[c] || null;
        }
      } else {
        return null;
      }
    }, obj);

  public static getUrl = (path: string) =>
    RegistrationUtils.getNestedProp(RegistrationUtils.Env().uris, path);

  public static getConstantValue = (path: string) =>
    RegistrationUtils.getNestedProp(RegistrationUtils.Env(), path);

  public static getPrefixUrl = (type: string) => {
    return RegistrationUtils.Env()?.uris[type];
  }

  public static getDefaultHeaders = () => {
    return RegistrationUtils.Env()?.uris.common.DEFAULT_HEADERS;
  }

  public static formatMillisecondsToDate = (milliseconds: number, format?: string) => {
    const date = new Date(milliseconds);
    const day = date.getDate()?.toString()?.padStart(2, '0');
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    let dateString = `${day} ${month} ${year} ${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    
    if (format === 'DD MMM YYYY') {
      const shortMonth = date.toLocaleString("default", { month: "short" });
      dateString =  `${day} ${shortMonth} ${year}`;
    }

    return dateString;
  };

  public static formatUTCTimestamp = (milliseconds: number, format?: string) => {
    const date = dayjs.utc(milliseconds);
    return date.format(format);
  }
}
