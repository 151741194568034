declare function __webpack_init_sharing__(def: string): void;
declare var __webpack_share_scopes__: { default: any };
import { JsonResolver, JsonModule } from './jsonResolver';


class ResolveFederatedModules {
      public static modules: any = {};

      private static loadScope = (url: string, scope: string | any) => {
            const element = document.createElement('script');
            const promise = new Promise((resolve, reject) => {
                  element.src = url;
                  element.type = 'text/javascript';
                  element.async = true;
                  element.onload = () => {
                        //element.parentElement?.removeChild(element);
                        // resolve(window[scope]);
                        resolve(window[scope]);
                  };
                  element.onerror = () => {
                        //element.parentElement?.removeChild(element);
                        reject;
                  };
            });
            document.head.appendChild(element);
            promise.finally(() => {
                  element.parentElement?.removeChild(element);
                  // document.head.removeChild(element);
            });
            return promise;
      };

      private static _prepareNonComponentModules = (module: any) => {
            ResolveFederatedModules.modules = {
                  hooks: {
                        useMediaQuery: module().useMediaQuery,
                        useBreakpoints: module().useBreakpoints,
                        useTranslation: module().useTranslation,
                        usePermissions: module().usePermissions,
                        useGridContext: module().useGridContext,
                        useNotification: module().useNotification,
                        useMessage: module().useMessage,
                        useContextNavigation: module().useContextNavigation,
                        useConfig: module().useConfig,
                        useForm: module().useForm
                  }
            }
      };
      public static loadModule = async (scope: string | any, module: string) => {
            try {
                  if (!window[scope]) {
                        const configData: any = JsonResolver.getJsonData(JsonModule.federation);
                        const scp: any = configData[scope];
                        const mdl: any = scp['module'].find((obj: any) => obj['name'] === module);
                        const container: any = await ResolveFederatedModules.loadScope(mdl['href'], scope);
                        await __webpack_init_sharing__('default');
                        await container.init(__webpack_share_scopes__.default);
                        const factory = await container.get(module);
                        if (scope === 'snpcomponents') {
                              ResolveFederatedModules._prepareNonComponentModules(factory);
                        }
                        return factory();
                  } else {
                        const container: any = window[scope];
                        //await __webpack_init_sharing__('default');
                        //await container.init(__webpack_share_scopes__.default);
                        const factory = await container.get(module);
                        return factory();
                  }
            } catch (error) {
                  console.error('Error loading module:', error);
                  throw error;
            }
      };
}

export default ResolveFederatedModules;