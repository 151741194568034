
import domains from '../../../assets/json/domains/DOMAINS.json';

export const LOCAL_STORAGE_KEY_CONTEXT = "__context";
export const SESSION_STORAGE_KEY_PUBLIC_CONTEXT = "__pubic_context";

export interface IContext {
    name: string;
    registryName: string;
    logo: string;
    logoAsHeader?: string;
    registry: string;
    cover: string;
    background?: string;
    color?: string;
    publicRoutes?: IPublicRoutesConfig;
    defaultStandard?: string;
}

export interface IPublicRoutesConfig {
    disableFooter: boolean;
}

export class ContextService {

    private static _defaultContext: string | null;
    private static _contexts: IContext[] | null;

    private static mapJsonContextToModel = (context: any): IContext => ({
        name: context.name,
        logo: context.logo,
        logoAsHeader: context.logoAsHeader,
        registryName: context.registryName,
        registry: context.registry,
        defaultStandard: context.defaultStandard,
        cover: context.cover,
        background: context.background,
        color: context.color,
        publicRoutes: context.publicRoutes
    });

    /**
     * This function retrieves the default context.
     *
     * @return {string | null} An string representing context, or null if it doesn't exist.
     */
    public static getDefautContext = (): string | null => {
        if (ContextService._defaultContext) {
            return ContextService._defaultContext;
        }

        if (domains && domains.length) {
            const defaultDomain = domains.find((domain: any) => domain.default === true);
            if (defaultDomain) {
                ContextService._defaultContext = defaultDomain.name;
                return ContextService._defaultContext;
            }
        }

        return null;
    }

    /**
     * This function retrieves the current context name.
     *
     * @return {string | null} An string representing context, or null if it doesn't exist.
     */
    public static getCurrentContextName = (): string | null => {
        return localStorage.getItem(LOCAL_STORAGE_KEY_CONTEXT);
    }

    /**
     * This function retrieves the current context name.
     *
     * @return {string | null} An string representing context, or null if it doesn't exist.
     */
    public static getPublicContextName = (): string | null => {
        return sessionStorage.getItem(SESSION_STORAGE_KEY_PUBLIC_CONTEXT);
    }

    /**
     * This function sets the current context name.
     */
    public static setCurrentContextName = (context: string): void => {
        localStorage.setItem(LOCAL_STORAGE_KEY_CONTEXT, context);
    }

    /**
     * This function sets the current context name.
     */
    public static setPublicContextName = (context: string): void => {
        sessionStorage.setItem(SESSION_STORAGE_KEY_PUBLIC_CONTEXT, context);
    }

    /**
     * This function retrieves the default context.
     *
     * @return {IContext[] | null} An string representing context, or null if it doesn't exist.
     */
    public static getContexts = (): IContext[] | null => {
        if (ContextService._contexts) {
            return ContextService._contexts;
        }

        if (domains && domains.length) {
            ContextService._contexts = domains.map(ContextService.mapJsonContextToModel);
            return ContextService._contexts;
        }

        return null;
    }

    /**
    * This function retrieves the current context.
    *
    * @return {IContext | null} An string representing current context, or null if it doesn't exist.
    */
    public static getCurrentContext = (): IContext | null => {
        const currentContextName = ContextService.getCurrentContextName();
        if (currentContextName) {
            return ContextService.getContext(currentContextName);
        }
        return null;
    }


    /**
    * This function retrieves the current public context.
    *
    * @return {IContext | null} An string representing current context, or null if it doesn't exist.
    */
        public static getCurrentPublicContext = (): IContext | null => {
            const currentPublicContextName = ContextService.getPublicContextName();
            if (currentPublicContextName) {
                return ContextService.getContext(currentPublicContextName);
            }
            return null;
        }

    /**
    * This function retrieves the context.
    *
    * @return {IContext | null} An string representing context, or null if it doesn't exist.
    */
    public static getContext = (name: string): IContext | null => {
        if (ContextService._contexts) {
            return ContextService._contexts.find(context => context.name === name) ?? null;
        }

        if (domains && domains.length) {
            ContextService._contexts = domains.map(ContextService.mapJsonContextToModel);
            return ContextService._contexts.find(context => context.name === name) ?? null;
        }

        return null;
    }

    /**
    * This function clears the current context.
    *
    * @return {void}
    */
    public static clearCurrentContext = (): void => {
        localStorage.removeItem(LOCAL_STORAGE_KEY_CONTEXT);
    }
}