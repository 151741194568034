import { JsonResolver, JsonModule, ResolveFederatedModules } from "@snp/libraries/config";

console.log(typeof JsonResolver);


(() => {
      JsonResolver.loadAllJson().then(() => {
            import("./index");
      })
})();
