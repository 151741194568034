type FilterButtonsType = 'apply' | 'clear' | 'reset' | 'cancel';
export interface FilterValuesType { [key: string]: string[]; }

interface IColumnFilter {
    filterType?: string;
    serviceCall?: any;
    values?: string[]; // Strict format for the filter functionality
    isHidden?: boolean;
    valueFormatter?: (val?: string) => void;
    comparatorFunction?: (val1: string, val2: string) => void;
    suppressSorting?: boolean;
    filterButtons?: (FilterButtonsType)[];
    filter?: string;
    debounceMs?: number;
    filterOptions?: string[];
    suppressAndOrCondition?: boolean;
    readOnly?: boolean;
    suppressSelectAll?: boolean;
}

export interface IRestoreDefaultValues {
    gridApi: any;
    values: string[];
    filterName: string;
}

export interface IValuesParams {
    colDef: any;
    success: (values: string[]) => void;
}

interface IValueFormatter {
    value?: string;
}

export const GRID_TEXT_FILTER: string = 'agTextColumnFilter';
export const GRID_DROPDOWN_FILTER: string = 'agSetColumnFilter';
export const GRID_DATE_FILTER: string = 'agDateColumnFilter';
export const GRID_NUMBER_FILTER: string = 'agNumberColumnFilter';
export const ROWS_ACTION_TYPE: string = 'keep';
export const DEFAULT_FILTER_BUTTONS: (FilterButtonsType)[] = ['apply', 'clear'];
export const GRID_DEFAULT_FILTER_TYPE: string = 'text';
export const GRID_DROPDOWN_FILTER_TYPE: string = 'set';
export const GRID_DATE_FILTER_TYPE: string = 'date';
export const GRID_NUMBER_FILTER_TYPE: string = 'number';

export const FILTER_CONFIG_PROPS = ['textSearchField', 'dropdownSearchField', 'dateSearchField', 'booleanSearchField', 'numberSearchField'];

export const COMMON_FILTER_FIELDS = {
    filter: GRID_TEXT_FILTER,
    floatingFilter: true,
}

export const createCustomFilter = ({
    filterType = GRID_DEFAULT_FILTER_TYPE,
    serviceCall,
    values,
    isHidden = false,
    valueFormatter,
    comparatorFunction,
    suppressSorting = false,
    filterButtons = DEFAULT_FILTER_BUTTONS,
    filter = GRID_DROPDOWN_FILTER,
    debounceMs = 0,
    suppressAndOrCondition = true,
    filterOptions,
    readOnly = false,
    suppressSelectAll = false,
}: IColumnFilter) => {
    return {
        hide: isHidden,
        filter,
        filterType,
        valueFormatter: ({ value }: IValueFormatter) => {
            return valueFormatter && valueFormatter(value);
        },
        filterParams: {
            buttons: filterButtons,
            floatingFilter: true,
            suppressSorting,
            newRowsAction: ROWS_ACTION_TYPE,
            filterOptions,
            values: (params: IValuesParams) => {
                if (values && values.length) {
                    params.success(values);
                } else {
                    if (serviceCall) {
                        serviceCall(params);
                    }
                }
            },
            comparator: (item1: string, item2: string) => {
                if (comparatorFunction) {
                    comparatorFunction(item1, item2);
                }
            },
            keyCreator: params => params.value.id,
            valueFormatter: params => params.value.label,
            debounceMs,
            suppressAndOrCondition,
            readOnly,
            suppressSelectAll,
        }
    };
}

const getDateOnly = (dateWithTime: string) => {
    return dateWithTime?.substring(0, 10);
}

export const parseColumnFilters = (columnFilters: any, column: any): any => {
    if (columnFilters.filterType == GRID_DROPDOWN_FILTER_TYPE) {
        if(column?.cellDataType === 'Boolean') {
            const filterObj = [{
                filterType: 'Boolean',
                type: 'equals',
                filter: columnFilters?.values.length ? columnFilters?.values?.includes('Yes') ? 'true' : 'false' : 'Dummy',
            }];
            return filterObj;
        }
        if (typeof columnFilters?.values === 'object' && columnFilters?.values?.length > 0) {
            const filterObj = [{
                filterType: 'Text',
                type: 'in',
                filter: columnFilters?.values?.map((item: any) => `${item}`).join(',')
            }];
            return filterObj;
        } else if (typeof columnFilters?.values === 'string' || typeof columnFilters?.values === 'number') {
            const test = [{
                filterType: 'Text',
                type: 'equals',
                filter: isNaN(Number(columnFilters?.values)) ? columnFilters?.values : Number(columnFilters?.values)
            }];
            return test;
        }
    } else if (columnFilters.filterType == GRID_DATE_FILTER_TYPE) {
        switch (columnFilters.type) {
            case 'greaterThanOrEqual': {
                return [{
                    filter: getDateOnly(columnFilters.dateFrom),
                    type: columnFilters.type,
                    filterType: 'Date'
                }]
            }
            case 'lessThanOrEqual': {
                return [{
                    filter: getDateOnly(columnFilters.dateFrom),
                    type: columnFilters.type,
                    filterType: 'Date'
                }]
            }
            case 'inRange': {
                return [{
                    filter: getDateOnly(columnFilters.dateFrom),
                    type: 'greaterThanOrEqual',
                    filterType: 'Date'
                },
                {
                    filter: getDateOnly(columnFilters.dateTo),
                    type: 'lessThanOrEqual',
                    filterType: 'Date'
                }]
            }
        };
    }

    else if (columnFilters.filterType == GRID_NUMBER_FILTER_TYPE) {
        if (columnFilters.type === 'inRange') {
            return [{
                filter: columnFilters.filter,
                type: 'greaterThanOrEqual',
                filterType: 'Number'
            },
            {
                filter: columnFilters.filterTo,
                type: 'lessThanOrEqual',
                filterType: 'Number'
            }]
        }
        else {
            return [{
                ...columnFilters,
                filterType: 'Number'
            }]
        }
    }

    else if (columnFilters.filterType == GRID_DEFAULT_FILTER_TYPE) {
        return [{
            ...columnFilters,
            filterType: 'Text'
        }];
    }
}