import { Observable } from "rxjs";
import { HttpService } from "./httpService";
import { RegistrationUtils } from "@snp/libraries/utils";
import { StandardService } from "./standardService";

// Will only use local files.
const USE_LOCAL_FILES_FLAG = false;
// Will try to fall back to local files when remote API fails.
const FALL_BACK_TO_LOCAL = true;

export const enum AssetType {
    Schema = 'Schema',
    SchemaOverride = 'SchemaOverride',
    TableConfig = 'TableConfig',
    Translation = 'Translation',
    StandardLogo = 'StandardLogo',
    Image = 'Image',
}

export class AssetsService {

    /**
     * Fetches assets data either from local assets directory or remote API.
     *
     * @param {AssetType} assetType - Asset type.
     * @param {string} configKey - ConfigKey of the asset.
     * @param {any} forceLocal - (Optional ) When set to true, will not try to fetch from API but use local file.
     * @param {any} forceFallBackToLocal - (Optional ) When set to true, will force fallback to local file.
     * @param {boolean} isPublic - (Optional) When set to true, will use unauthenticated call to the backend.
     * @return {Observable<any>} An Observable that emits the fetched data.
     */
    public static getAsset = (assetType: AssetType, configKey: string, forceLocal = false, forceFallBackToLocal = false, isPublic = false): Observable<any> => {
        return new Observable<any>((subscriber: any) => {
            if (USE_LOCAL_FILES_FLAG || forceLocal) {
                AssetsService.subsctibeToLocalFile(assetType, configKey, subscriber);
            }
            else {
                const assetUrl = AssetsService.getAssetUrl(assetType, configKey);
                const url = `${RegistrationUtils.getUrl("cmsResources")}${assetUrl}`;
                const headers = AssetsService.getAssetHeaders(assetType, configKey);
                HttpService.get(url, isPublic, headers, (assetType === AssetType.StandardLogo || assetType === AssetType.Image)).subscribe(
                    (response: any) => {
                        subscriber.next(response);
                        subscriber.complete();
                    },
                    (error: any) => {
                        console.log('error', error);
                        if (FALL_BACK_TO_LOCAL || forceFallBackToLocal) {
                            AssetsService.subsctibeToLocalFile(assetType, configKey, subscriber);
                        }
                    });
            }
        });
    }

    private static subsctibeToLocalFile(assetType: AssetType, configKey: string, subscriber: any): any {
        const assetLocalUrl = AssetsService.getAssetLocalUrl(assetType, configKey);

        const isBlob = assetType === AssetType.StandardLogo || assetType === AssetType.Image;
        const selectedStandard = StandardService.getSelectedStandard();
        if (selectedStandard) {
            const assetLocalUrlWithStandard = AssetsService.getAssetLocalUrl(assetType, configKey, selectedStandard.metaData);
            fetch(assetLocalUrlWithStandard)
                .then((response: any) => {
                    if (!response.ok || (!isBlob && !AssetsService.isJsonResponse(response))) {
                        console.log('Trying fallback to route with no standard');
                        fetch(assetLocalUrl)
                            .then((response: any) => {
                                const contentPromise = isBlob ? response.blob() : response.json();
                                contentPromise.then((json: any) => {
                                    subscriber.next(json);
                                    subscriber.complete();
                                })
                            })
                            .catch((innerError: any) => {
                                console.log('catch', innerError);
                                subscriber.error(innerError);
                                subscriber.complete();
                            });
                    }
                    else {
                        const contentPromise = isBlob ? response.blob() : response.json();
                        contentPromise.then((json: any) => {
                            subscriber.next(json);
                            subscriber.complete();
                        })
                    }
                })
                .catch((error: any) => {
                    console.log('catch', error);
                    subscriber.error(error);
                    subscriber.complete();
                });
        }
        else {
            fetch(assetLocalUrl)
                .then((response: any) => {
                    if(!response.ok) {
                        subscriber.error('Error response from the API.');
                        subscriber.complete();
                    }

                    const contentPromise = isBlob ? response.blob() : response.json();
                    contentPromise.then((json: any) => {
                        subscriber.next(json);
                        subscriber.complete();
                    })
                })
                .catch((error: any) => {
                    console.log('catch');
                    subscriber.error(error);
                    subscriber.complete();
                });
        }
    }

    private static getAssetUrl = (assetType: AssetType, configKey: string): string => {
        switch (assetType) {
            case AssetType.Schema: {
                return `/resource/getContent/schema/${configKey}`;
            }
            case AssetType.SchemaOverride: {
                return `/resource/getContent/standardOverrides/${configKey}`;
            }
            case AssetType.TableConfig: {
                return `/resource/getContent/table/${configKey}`;
            }
            case AssetType.Translation: {
                return `/resource/getContent/translation/${configKey}`;
            }
            case AssetType.StandardLogo: {
                return `/resource/download/logo/${configKey}_LOGO`;
            }
            case AssetType.Image: {
                return `/resource/download/image/${configKey.split('.')[0]}`;
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static getAssetHeaders = (assetType: AssetType, configKey: string): { [key: string]: string } => {
        switch (assetType) {
            case AssetType.Schema:
            case AssetType.SchemaOverride:
            case AssetType.TableConfig:
            case AssetType.Translation:
            case AssetType.Image: {
                return {
                    'tenant': 'SPG'
                }
            }
            case AssetType.StandardLogo: {
                return {
                    'tenant': 'SPG',
                    'acronym': configKey
                }
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static getAssetLocalUrl = (assetType: AssetType, configKey: string, standard?: string): string => {
        switch (assetType) {
            case AssetType.Schema: {
                return `/schemas/${standard ? `${standard}/` : ''}${configKey}.json`;
            }
            case AssetType.SchemaOverride: {
                return `/schemas/standardOverrides/${configKey}.json`;
            }
            case AssetType.TableConfig: {
                return `./json/table-config/${standard ? `${standard}/` : ''}${configKey}.json`;
            }
            case AssetType.Translation: {
                return `./i18n/${standard ? `${standard}/` : ''}${configKey}.json`;
            }
            case AssetType.StandardLogo: {
                return `./images/standard/${configKey}.gif`;
            }
            case AssetType.Image: {
                return `./images/${configKey}`;
            }
            default: throw new Error("Invalid assetType.");
        }
    }

    private static isJsonResponse(response: any): boolean {
        const contentType: string = response?.headers?.get("Content-Type");
        return contentType?.includes('application/json') || contentType?.includes('application/octet-stream') ? true : false;
    }
}