import { JsonResolver, JsonModule } from '@snp/libraries/config'
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpService } from '@snp/libraries/core';
import {
    CellClassParams,
    IGetRowsParams,
    SortModelItem,
} from 'ag-grid-community';
import {
    COMMON_FILTER_FIELDS,
    FILTER_CONFIG_PROPS,
    GRID_DROPDOWN_FILTER,
    IValuesParams,
    createCustomFilter,
    parseColumnFilters,
    GRID_DATE_FILTER,
    GRID_NUMBER_FILTER,
} from 'packages/libraries/utils/src/gridHelpers';
import { CMS_RESOURCE_MANAGER, RegistrationUtils } from '@snp/libraries/utils';
import { IGridOptions } from 'packages/components/src/federated';
import dayjs from 'dayjs';

import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'

dayjs.extend(localeData)
dayjs.extend(utc)

const DEFAULT_PAGE_SIZE = 100;

const COMMON_REQUEST_BODY_PARAMS = {
    entity_domain: 'Markit',
    is_registry: true,
}

export enum IGridSortDir {
    ASC = 'ASC',
    DESC = 'DESC'
}

export enum AGGridRowModel {
    CLIENTSIDE = 'clientSide',
    SERVERSIDE = 'serverSide',
    INIFINITE = 'infinite',
    VIEWPORT = 'viewport'
}

export interface IGridPagination {
    start: number;
    limit: number;
    sort?: string;
    dir?: IGridSortDir;
    filterModel?: any;
}

export interface IFilterState {
    selectedValue: string | null;
    values: any[];
}

export interface IFilterContext {
    [key: string]: IFilterState;
};

const DEFAULT_PAGINATION: IGridPagination = {
    start: 0,
    limit: DEFAULT_PAGE_SIZE,
    // sort: 'account_name',
    // dir: IGridSortDir.ASC,
}

export class GridService {

    private static env = JsonResolver.getJsonData(JsonModule.environment);

    private _pagination: any = DEFAULT_PAGINATION;
    private _filterModel: any = {};

    private get baseUri(): string {
        return GridService.env.uris.baseUri;
    }

    private get cmsUri(): string {
        return GridService.env.uris.cmsUri;
    }

    private get uris(): any {
        return GridService.env.uris;
    }

    public get pagination(): any {
        return this._pagination;
    }

    public get filterModel(): any {
        return this._filterModel;
    }

    public setRequestParams(params: IGetRowsParams, api: any, defaultSortColumn: string, defaultDir: string = 'ASC') {
        const { startRow, endRow, filterModel, sortModel } = params

        const limit = (endRow || DEFAULT_PAGE_SIZE) - (startRow || 0);
        const columns = api.getColumnDefs();

        this._pagination = {
            ...this._pagination,
            start: startRow || 0,
            limit,
        }

        if (!this._pagination.sort) {
            this._pagination = {
                ...this._pagination,
                sort: defaultSortColumn,
                dir: defaultDir,
            }
        }

        if (sortModel && sortModel.length) {
            const sorting = sortModel.map((s: SortModelItem) => ({ sort: GridService.getSortField(columns, s), dir: s.sort.toUpperCase() }))[0];
            this._pagination = {
                ...this._pagination,
                ...sorting,
            }
        }

        if (filterModel) {
            const _filterModel: any = {};
            const columns = api.getColumnDefs();
            Object.keys(filterModel).map(fieldName => {
                const column = columns.find(c => c.field === fieldName);
                _filterModel[column.filterField] = {
                    columnFilters: parseColumnFilters(filterModel[fieldName], column)
                }
            });
            this._filterModel = _filterModel;
        } else {
            this._filterModel = {};
        }
    }

    // IF SORTABLE COLID NEED TO BE DIFFERENT THAN FIELD COLID
    private static getSortField(columns: any, s: SortModelItem): string {
        const colData = columns.find((x: any) => x.field === s.colId);
        if (colData.sortable !== colData.field) {
            return colData.sortable;
        }
        return colData.field;
    }

    // TODO change logic here
    private static mapCellDataType(columnDef: any) {
        let _type = 'text';
        if (columnDef.numeric) {
            _type = 'number'
        } else if (columnDef.fieldType === 'Boolean') {
            _type = columnDef.fieldType;
        }
        return _type;
    }

    // TODO change logic here
    private static customCellRenderer(columnDef: any, gridRef: any, onChange: () => void): any {
        const opts: any = {};
        if (columnDef.fieldType === 'chips') {
            opts.cellRenderer = 'gridRibbon';
            if (columnDef.translationPrefix) {
                opts.cellRndererParams = {
                    translationPrefix: columnDef.translationPrefix,
                }
            }
        } else if (columnDef.fieldType === 'CustomComponent') {
            opts.cellRenderer = columnDef.cellRenderer;

            const cellRendererParams = columnDef?.cellRendererParams || {};
            opts.cellRendererParams = {
                ...cellRendererParams,
                onChange,
                gridRef: gridRef,
            }
        }

        return opts;
    }

    private getPayload(extraFilters: any = {}, extraFilterModelOptions: any = {}): any {

        Object.keys(extraFilterModelOptions).map(fieldName => {
            this.filterModel[fieldName] = {
                columnFilters: extraFilterModelOptions[fieldName].columnFilters
            }
        });
        return {
            //...COMMON_REQUEST_BODY_PARAMS,
            searchFilter: {
                pagination: this.pagination,
                filterModel: extraFilters?.groupKeys?.length ? {} : this.filterModel,   //  sending empty filterModel if groupKeys is present
                ...extraFilters,
            }
        }
    }

    public static dictValueFormatter(item) {
        if (!item) { return null };
        return typeof item === 'string' ? item : item.label;
    };

    public loadConfig(tableConfigName: string): Observable<any> {
        const url = `${this.cmsUri}${this.uris.common.gridConfiguration}/${tableConfigName}`
        return HttpService.get(url);
    }

    private static getFilterConfig(col: any, rowModel: AGGridRowModel, filterContext: IFilterContext): any {
        const filterKey = FILTER_CONFIG_PROPS.find(prop => col[prop] && col[prop] !== null);

        if (filterKey) {
            //TODO : @Wojciech to add the filter functionality
            if (filterKey === 'textSearchField') {
                return {
                    ...COMMON_FILTER_FIELDS,
                    filterField: col[filterKey],
                    filterParams: {
                        filterOptions: ['contains'],
                        maxNumConditions: 1
                    }
                }
            } else if (filterKey === 'numberSearchField') {
                return {
                    ...COMMON_FILTER_FIELDS,
                    filterField: col[filterKey],
                    filter: GRID_NUMBER_FILTER,
                    filterParams: {
                        filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
                        maxNumConditions: 1,
                        buttons: ['clear']
                    }
                }
            } else if (filterKey === 'dateSearchField') {
                return {
                    ...COMMON_FILTER_FIELDS,
                    filterField: col[filterKey],
                    filter: GRID_DATE_FILTER,
                    filterParams: {
                        maxNumConditions: 1,
                        filterOptions: ['inRange', 'lessThanOrEqual', 'greaterThanOrEqual'],
                        inRangeFloatingFilterDateFormat: 'DD MMM YYYY',
                        buttons: ['clear']
                    }
                }
            }
            else if (filterKey === 'booleanSearchField') {
                return {
                    ...COMMON_FILTER_FIELDS,
                    filter: GRID_DROPDOWN_FILTER,
                    filterField: col[filterKey],
                    filterParams: {
                        values: ['Yes', 'No'],
                        suppressSorting: true,
                        floatingFilter: true,
                    }
                }
            } else if (filterKey === 'dropdownSearchField') {
                if (rowModel == AGGridRowModel.CLIENTSIDE) {
                    return {
                        ...COMMON_FILTER_FIELDS,
                        filter: GRID_DROPDOWN_FILTER,
                    }
                } else {
                    if (col.filterUrl) {
                        return {
                            ...COMMON_FILTER_FIELDS,
                            filterField: col[filterKey],
                            ...createCustomFilter({
                                serviceCall: (params: IValuesParams) => GridService.getFilterData(params),
                                valueFormatter: (item: any) => GridService.dictValueFormatter(item),
                                readOnly: col.readOnly,
                                suppressSelectAll: col.suppressSelectAll,
                            })
                        }
                    }

                    if (col.filterContextName) {
                        return {
                            ...COMMON_FILTER_FIELDS,
                            filterField: col[filterKey],
                            ...createCustomFilter({
                                values: (filterContext as any)[col.filterContextName]?.values,
                                valueFormatter: (item: any) => GridService.dictValueFormatter(item),
                                readOnly: col.readOnly,
                                suppressSelectAll: col.suppressSelectAll,
                            }),
                            filterContextName: col.filterContextName
                        }
                    }

                    throw new Error('Undefined filter configuration.');
                }
            }
        } else {
            return {
                filter: false,
            };
        }

    }

    private static valueFormatter(params: any) {
        let _type = params.value;
        if (params.colDef.cellDataType === 'Boolean') {
            _type = params.value ? 'Yes' : 'No';
        }

        if (params?.colDef?._srcDef?.fieldType === 'Date'
            && params?.colDef?._srcDef?.format
            && params?.colDef?._srcDef?.convertFromUtc) {

            _type = dayjs(params.value)
                .utc(true)
                .local()
                .format(params.colDef._srcDef.format);
        }

        return _type;
    }

    public static parseColumns(tableConfig: any, translate: any, gridOptions: IGridOptions = {}, gridRef?: any, filterContext: IFilterContext): any {
        const _columns: ColDef[] = tableConfig.columns.map((col: any, index: number) => ({
            id: col.id,
            rowGroup: !!col.columnGroup,
            headerName: tableConfig.translationPrefix ?
                translate(`${tableConfig.translationPrefix}.${col.header}`) :
                col.header,
            headerTooltip: tableConfig.translationPrefix ?
            translate(`${tableConfig.translationPrefix}.${col.headerTooltip}`) :
            col.headerTooltip,
            field: col.field,
            ...col.width != 'auto' ? { minWidth: col.width } : {},
            flex: 1,
            cellDataType: GridService.mapCellDataType(col),
            cellStyle: (params: CellClassParams) => {
                if (!!params.node.master && !params.node.group && (index === 0) && !gridOptions.detailCellRendererParams) {
                    return { paddingLeft: '40px' }
                }
            },
            resizable: true,
            format: col.format,
            sortable: col.sortField || false,
            hide: col.hide,
            valueFormatter: GridService.valueFormatter,
            suppressFloatingFilterButton: true,
            ...this.customCellRenderer(col, gridRef, tableConfig.onChange),
            ...this.getFilterConfig(col, gridOptions.rowModel, filterContext),
            _srcDef: col,
            permission: col?.viewPermissionName,

            // SETTING BELOW PROPERTIES TO PIN THE FIRST COLUMN DEFINED IN TABLE CONFIG FOR GRIDS THAT SUPPORTS GROUPED ROWS
            lockPosition: (!!gridOptions.masterDetail && !gridOptions.detailCellRendererParams && index === 0) && 'left',
            pinned: (!!gridOptions.masterDetail && !gridOptions.detailCellRendererParams && index === 0) && 'left',
            suppressHeaderMenuButton: (!!gridOptions.masterDetail && !gridOptions.detailCellRendererParams && index === 0),
        }));

        if (tableConfig.checkboxSelection) {
            _columns[0] = {
                ..._columns[0],
                headerCheckboxSelection: true,
                checkboxSelection: true,
                cellRendererParams: {
                    checkbox: true,
                },
            }
        }

        return _columns;
    }

    public loadData(dataUri: string, body = {}, extraFilters: any = {}, extraFilterModelOptions: any = {}, isPublic: boolean): Observable<any> {
        const uri = `${this.baseUri}${dataUri}`;
        const payload = { ...this.getPayload(extraFilters, extraFilterModelOptions), ...body };
        return HttpService.post(uri, payload, isPublic);
    }

    public handleExportBtnClick(body = {}, extraFilters: any = {}, tableConfig: any, fileName: string): Observable<void> {
        const payload = { ...this.getPayload(extraFilters), ...body };
        delete payload.searchFilter.pagination.limit;   //  DELETING THE LIMIT PROPERTY FROM EXPORT ALL PAYLOAD, SINCE NOT REQUIRED
        const reqPayload = {
            exportSearch: {
                searchFilter: payload.searchFilter
            },
            responseType: "EXCEL",
            reportType: tableConfig.tableViewName
        };
        const url = `${RegistrationUtils.getPrefixUrl(CMS_RESOURCE_MANAGER)}/export`;

        return new Observable<void>((subscriber) => {
            HttpService.post(url, reqPayload, false, true, {
                tenant: 'SPG',
                accept: 'application/octet-stream'
            }).subscribe(
                (data: any) => {
                    const blobUrl = URL.createObjectURL(data);

                    // Create a link element
                    const link = document.createElement('a');
                    link.href = blobUrl;


                    // Specify the filename for the downloaded file
                    link.download = fileName;

                    // Simulate a click on the link to trigger the download
                    link.click();

                    // Clean up by revoking the object URL
                    URL.revokeObjectURL(blobUrl);
                    subscriber.next();
                    subscriber.complete();
                },
                (error: any) => {
                    // Handle error
                    console.error('Error exporting table data:', error);
                    subscriber.next();
                    subscriber.complete();
                }
            );
        });

    };

    public getdData(dataUri: string, extraPathParams: string = ''): Observable<any> {
        const uri = `${this.baseUri}${dataUri}${extraPathParams}`;
        return HttpService.get(uri);
    }

    public static getFilterData(params: IValuesParams) {
        const filterUrl = params?.colDef?._srcDef?.filterUrl;
        const url = `${RegistrationUtils.getPrefixUrl(filterUrl?.type)}/${filterUrl?.endPoint
            }`;
        // const url = `${ GridService.env.uris[params?.colDef?._srcDef?.filterUrl] }`;
        const isPublicUrl = filterUrl?.isPublic || false;
        HttpService.get(url, isPublicUrl)
            .pipe(
                map((data: any) =>
                    data.map((item: any) => ({
                        ...item,
                        label: item.name,
                        value: item.id,
                    }))
                )
            ).subscribe((data: any) => {
                //params.success(data.map(v => v.label));
                params.success(data.sort((a: any, b: any) => (a.label < b.label ? -1 : 1)));
            });
    };

}