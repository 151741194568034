export const DOC_UPLOAD_RESPONSE_CODE:any = {
  LINK_ACTIVATED : {
    sessionExpMsg:'',
    supportMail: 'successReachOutMsg',
    showFileSubmitted: true,
    showNewLinkButton: false,
    showSessionExpired: false,
    sessionExpTitle: '',
    iconClass: '',
    iconType: '',
  },
  EXPIRED_LINK: {
    sessionExpMsg:'secondLinkSessionExpMsg',
    supportMail: 'reachOutMsg',
    showFileSubmitted: false,
    showNewLinkButton: false,
    showSessionExpired: true,
    sessionExpTitle: 'docSessionExp',
    iconClass: 'fa-light fa-triangle-exclamation',
    iconType: 'waring',
  },
  REQUEST_NEW_LINK: {
    sessionExpMsg:'sessionExpiredMsg',
    supportMail: '',
    showFileSubmitted: false,
    showNewLinkButton: true,
    showSessionExpired: true,
    sessionExpTitle: 'docSessionExp',
    iconClass: 'fa-light fa-triangle-exclamation',
    iconType: 'waring',
  },
  INCORRECT_LINK_USED: {
    sessionExpMsg:'secondLinkSessionExpMsg',
    supportMail: 'reachOutMsg',
    showFileSubmitted: false,
    showNewLinkButton: false,
    showSessionExpired: true,
    sessionExpTitle: 'docSessionExp',
    iconClass: 'fa-light fa-triangle-exclamation',
    iconType: 'waring',
  },
  SECOND_LINK_ALSO_EXPIRED: {
    sessionExpMsg:'secondLinkSessionExpMsg',
    supportMail: 'reachOutMsg',
    showFileSubmitted: false,
    showNewLinkButton: false,
    showSessionExpired: true,
    sessionExpTitle: 'docSessionExp',
    iconClass: 'fa-light fa-triangle-exclamation',
    iconType: 'waring',
  },
  EMAIL_SENT: {
    sessionExpMsg:'emailSentMsg',
    supportMail: 'reachOutMsg',
    showNewLinkButton: false,
    sessionExpTitle: 'emailSent',
    iconClass: 'fa-regular fa-envelope-circle-check',
    iconType: 'success',
  }
};

interface Entity {
  [key: string]: string[];
}

export interface DocumentMandate {
  entity: Entity
  success: boolean
  message: string
  id: number
}

export const DEFAULT_ERROR_MSG = "Something went wrong. please try again after some time.";

export const TIME_OUT = 5000;
export const UNITS_NAVIGATION = '/units';
export const RFI_NAVIGATION = '/rfi';

export const PROJECT_TYPES = {
  STANDALONE_PROJECT : 'STANDALONE_PROJECT',
  SUB_PROJECT: 'SUB_PROJECT',
  MASTER_PROJECT: 'MASTER_PROJECT'
};

export const DOCUMENT_MANAGER = "documentManager";
export const PROJECT_MANAGER = "projectManager";
export const LOOKUP_MANAGER = "lookupManager";
export const USER_MANAGER = "userManager";
export const MASTER_PROJECT_MANAGER = "masterProjectManager";
export const PUBLIC_ACCOUNT_MANAGER = "publicAccountManager";
export const STATIC_DATA_SERVICE = "staticDataService";
export const AUTH_CODE = "authorizationCode";
export const ACCOUNT_MANAGER = "accountManager";
export const PK_MANAGER = "pkManager";
export const PROJECT_MILESTONE_MANAGER = "wccProjectMilestoneManager";
export const RAAS_REPORT_MANAGER = "raasReportManager";
export const MOVE_PROJECT_MANAGER = "moveProjectManager";
export const MARKET_ISSUANCE_MANAGER = "issuanceManager";
export const WORKFLOW_CONFIG_MANAGER = "worklowConfigManager";
export const RETIREMENT_CERTIFICATE_MANAGER = "retirementCertificateManager";
export const RAAS_REPORT_PUBLIC_MANAGER = "raasReportPublicManager";
export const UNITS_MANAGER = "unitsManager";
export const PUBLIC_REPORT_MANAGER = "publicReportManager";
export const DOCUMENT_PUBLIC_MANAGER = "documentManagerPublic";
export const ADDITIONAL_INFO_MANAGER = "additionalInformationManager";
export const CMS_RESOURCE_MANAGER = "cmsResources";
export const RAAS_REPORT_ISSUANCE_MANAGER = "raasReportIssuanceManager";
export const RFI_MANAGER = "rfiManager";
export const PROJECT_MANAGER_BASE_URI = "projectManagerUri";
export const MASTER_PROJECT = "master-project-manager";
export const PROJECT_MANAGER_URI = "project-manager";
export const MOVE_PROJECT_MANAGER_URI = "move-project-manager";
export const PROJECT_MILESTONE_MANAGER_URI = "project-milestone-manager";
export const RAAS_URI = "raasUri";
export const CMS_URI = "cmsResources";

// RAAS_CREDIT_URI
export const RAAS_CREDIT_BASE_URI = "raasCreditUri";
export const RFI_MANAGER_URI = "rfi-manager";

export const PENDING_REVIEW_STATUS = 'PENDING_MOP';
export const PENDING_APPROVAL_MOP = 'PENDING_APPROVAL_MOP';
export const PENDING_CUST_DEV = 'PENDING_CUST_DEV';
export const PENDING_REVIEW_VALIDATION_CUSTOMER = 'PENDING_REVIEW_VALIDATION_CUSTOMER';
export const PENDING_REVIEW_VALIDATION_MARKIT = 'PENDING_REVIEW_VALIDATION_MARKIT';
export const PENDING_REVIEW_VALIDATION_VALIDATOR = 'PENDING_REVIEW_VALIDATION_VALIDATOR';
export const PENDING_REVIEW_UNDER_DEVELOPMENT_3RD_PARTY = 'PENDING_REVIEW_UNDER_DEVELOPMENT_3RD_PARTY';
export const PENDING_MOP_UNDER_DEV = 'PENDING_MOP_UNDER_DEV';
export const PENDING_3RD_VAL = 'PENDING_3RD_VAL';

export const ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';
export const ACCOUNT_CONTRIBUTOR = 'ACCOUNT_CONTRIBUTOR';
export const ACCOUNT_READER = 'ACCOUNT_READER';
export const PROGRAM_ADMIN= 'PROGRAM_ADMIN';
export const PROGRAM_CONTRIBUTOR= 'PROGRAM_CONTRIBUTOR';
export const PROGRAM_READER= 'PROGRAM_READER';
export const REGISTRY_ADMIN= 'REGISTRY_ADMIN';
export const REGISTRY_CONTRIBUTOR= 'REGISTRY_CONTRIBUTOR';
export const REGISTRY_READER= 'REGISTRY_READER';
export const PENDING_APPROVAL_3RD = 'PENDING_APPROVAL_3RD';
export const ACTIVE = "ACTIVE";
export const TRANSFER_REQUESTED = 'TRANSFER_REQUESTED';


export const SELECT_BENEFICIAL_OWNER_INFO_MSG = "Beneficial Owner is the individual or corporation claiming the ultimate environmental benefit from the retirement of a unit.";

export const SHORT_MAX_LENGTH_INPUT = 50;
export const SHORT_MAX_LENGTH_TEXTBOX = 300;
export const LONG_MAX_LENGTH_TEXTBOX = 2000;

export const STRUCTURE_DOC_DISPLAY = {
  TREE: 'TREE',
  NORMAL: 'NORMAL'
}

export const FileTypes = [{type: 'application/pdf', extension: '.pdf'}, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: '.xlsx'}, {type: 'application/vnd.ms-excel', extension: '.xls'}];

export const REGISTRY_SUPPORT_MAIL = 'email.group.registrySupport';
export const GUIDELINE_LINK = "spg.account.guidelines.link";
export const TERMS_OF_USE_LINK = "spg.terms.use";
